<template>
  <div class="message-container">
    <div class="message-title">
      <h2>Permiso denegado</h2>
      <h3 class="grey-text">
        No tenés permisos para acceder a esta página.
      </h3>
      <h4>
        Para más información podés comunicarte con el administrador.
      </h4>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';


export default {
    name: "Forbidden",
    created() {
      const target = this.target ?? '/';
      const router = this.$router;
      const targetRoute = router.resolve(target).route;
      if (!targetRoute.matched.length || (targetRoute.matched.length === 1 && ['*', '/', ''].includes(targetRoute.matched[0].path))) {
          return router.push('/');
      }

      if (this.currentUser.hasAuthorities(targetRoute.matched)) {
          return router.push({ path: target });
      }
    },
    computed: {
      ...mapGetters(['currentUser']),
      target() {
        return this.$router.currentRoute?.query?.t;
      }
    }
};
</script>

<style lang="scss" scoped>

.message-title {
  margin-bottom: 10px !important;
  padding-bottom: 25px !important;
  padding-top: 10px !important;
}

.message-container {
  text-align: center;
  margin-top: 13%;
}

.grey-text {
  color: grey;
}

</style>
